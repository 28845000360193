import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/mdx-default.tsx";
import MdxMultiSection from '../../templates/mdx-multi-section.tsx';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const NavSecondary = makeShortcode("NavSecondary");
const layoutProps = {
  _frontmatter
};
const MDXLayout = MdxMultiSection;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <a id="people"></a>
    <section className="main style1">
      <header className="small">
        <h2 {...{
          "id": "emrg-people"
        }}>{`EMRG: People`}</h2>
      </header>
      <div className="features">
        <section>
          <p><span parentName="p" {...{
              "className": "gatsby-img-attributes",
              "style": {
                "display": "block",
                "width": "60%",
                "marginLeft": "auto",
                "marginRight": "auto"
              }
            }}><span parentName="span" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "550px"
                }
              }}>{`
      `}<span parentName="span" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "100%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMEBf/EABcBAQEBAQAAAAAAAAAAAAAAAAIDAAH/2gAMAwEAAhADEAAAAdN+bYqbjFO9TLQKc//EAB0QAAICAQUAAAAAAAAAAAAAAAECAxEABBITISL/2gAIAQEAAQUCZyixScuPqypeVJBDdte4jpHNE+v/xAAYEQACAwAAAAAAAAAAAAAAAAABEAIhQf/aAAgBAwEBPwGIpYv/xAAZEQACAwEAAAAAAAAAAAAAAAAAAQIQESH/2gAIAQIBAT8Bb6aRr//EAB0QAAMAAQUBAAAAAAAAAAAAAAABESECEjFBYnH/2gAIAQEABj8CUJqhEiJZLt6hnkfyl8jwmf/EABwQAQACAgMBAAAAAAAAAAAAAAEAESExQVFxYf/aAAgBAQABPyG3m53B1zKSgO4VQYKOvIMQBkfsdmo2SrzQHJdJzN4i9s//2gAMAwEAAgADAAAAENzwP//EABcRAQEBAQAAAAAAAAAAAAAAAAEAIUH/2gAIAQMBAT8QB3BdQZf/xAAYEQEAAwEAAAAAAAAAAAAAAAABABARQf/aAAgBAgEBPxDbRFLOK//EAB0QAQEAAwACAwAAAAAAAAAAAAERACExQVFxgZH/2gAIAQEAAT8QVtKk4nzglZA4f1kqY2CtuFRQgtLH6e8oW+DSwzd66yTHIhsTCZVTY8tw4qBiUoZ6/CKrCZ//2Q==')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="span" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "John Crawford",
                  "title": "John Crawford",
                  "src": "/static/6312765579e37aa422bed367bb0a7ad1/021b5/jc-profile-550x550.jpg",
                  "srcSet": ["/static/6312765579e37aa422bed367bb0a7ad1/021b5/jc-profile-550x550.jpg 550w"],
                  "sizes": "(max-width: 550px) 100vw, 550px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy"
                }}></img>{`
    `}</span></span></p>
          <h3 className="compact">John Crawford</h3>
          <h5>Faculty/Director</h5>
          <p>{`John Crawford is an intermedia artist, performance director and systems designer. Engaging directly with the emerging technologies of our time, his work creates rich immersive environments and compelling interactive experiences through embodied interaction. His artistic projects are performed in theatres, exhibited in galleries and presented as interactive installations, featuring creative exploration of environmentalism, climate justice and community engagement.`}</p>
          <ul className="actions special">
  <li><a href="/people/john-crawford" className="button small">More</a></li>
          </ul>
        </section>
        <section>
          <p><span parentName="p" {...{
              "className": "gatsby-img-attributes",
              "style": {
                "display": "block",
                "width": "60%",
                "marginLeft": "auto",
                "marginRight": "auto"
              }
            }}><span parentName="span" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "1171px"
                }
              }}>{`
      `}<span parentName="span" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "100%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQDAf/EABYBAQEBAAAAAAAAAAAAAAAAAAIBA//aAAwDAQACEAMQAAAB7yjE3BWpmjNJsEf/xAAbEAACAgMBAAAAAAAAAAAAAAABAgARAxATIf/aAAgBAQABBQID1gqypVwjTOehYsy5Gr//xAAWEQADAAAAAAAAAAAAAAAAAAABEDH/2gAIAQMBAT8BERi//8QAGBEAAgMAAAAAAAAAAAAAAAAAAAEQETH/2gAIAQIBAT8BKFsf/8QAGhAAAgMBAQAAAAAAAAAAAAAAASEAEBECYf/aAAgBAQAGPwJnIureUB7HX//EABsQAAMBAAMBAAAAAAAAAAAAAAABESFBUWFx/9oACAEBAAE/IUOZGcv32jGDxP6KWqm44Qn7i6DddjEop//aAAwDAQACAAMAAAAQv/B9/8QAFxEBAQEBAAAAAAAAAAAAAAAAAAERsf/aAAgBAwEBPxCIa6Nr/8QAGBEAAwEBAAAAAAAAAAAAAAAAAAEREKH/2gAIAQIBAT8QdswvJEf/xAAaEAEBAQEBAQEAAAAAAAAAAAABEQAhMUFR/9oACAEBAAE/EASQe2Omky6JDov10AGThlM3Hg0syfEZ/XHEECgfMHBQ4UsN/9k=')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="span" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "Ivan Williams",
                  "title": "Ivan Williams",
                  "src": "/static/2f024f45bd6ce0d93f5b47eff035b703/6cd7b/ivan-williams_1x1.jpg",
                  "srcSet": ["/static/2f024f45bd6ce0d93f5b47eff035b703/3ad8d/ivan-williams_1x1.jpg 600w", "/static/2f024f45bd6ce0d93f5b47eff035b703/6cd7b/ivan-williams_1x1.jpg 1171w"],
                  "sizes": "(max-width: 1171px) 100vw, 1171px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy"
                }}></img>{`
    `}</span></span></p>
          <h3 className="compact">Ivan Williams</h3>
          <h5>Producer</h5>
          <p>{`Ivan Williams is a film, TV, and theatre executive producer with a passion for the arts. As a media and entertainment entrepreneur, he supports the deployment of state-of-the-art technology to streamline and diversify the development and presentation of new shows and films. Before becoming a filmmaker, he had a 35 year career as a chemical engineer and senior business leader with major energy related companies, including ARCO, BP and Worley Parsons.`}</p>
          <ul className="actions special">
  <li><a href="/people/ivan-williams" className="button small">More</a></li>
          </ul>
        </section>
        <section>
          <p><span parentName="p" {...{
              "className": "gatsby-img-attributes",
              "style": {
                "display": "block",
                "width": "60%",
                "marginLeft": "auto",
                "marginRight": "auto"
              }
            }}><span parentName="span" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "563px"
                }
              }}>{`
      `}<span parentName="span" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "100%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAUDAgT/xAAVAQEBAAAAAAAAAAAAAAAAAAACAf/aAAwDAQACEAMQAAABnda+gKOEdKBFMFn/xAAbEAADAAIDAAAAAAAAAAAAAAABAgMABBIxM//aAAgBAQABBQIocrETGTbOK02D2GKsHI2Ken//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAaEAACAwEBAAAAAAAAAAAAAAAAAQIQESEx/9oACAEBAAY/AjVLa6Ti/FSaFgz/xAAbEAEAAgIDAAAAAAAAAAAAAAABABExYRBBof/aAAgBAQABPyEJidxCk4xDgrGyyCkbmYAbIe0Lpdz2z//aAAwDAQACAAMAAAAQjDjC/8QAGBEAAwEBAAAAAAAAAAAAAAAAAAEREDH/2gAIAQMBAT8QcmIfT//EABgRAQEAAwAAAAAAAAAAAAAAAAEAEBEx/9oACAECAQE/EDa4Y5f/xAAbEAEAAwADAQAAAAAAAAAAAAABABEhMUFRgf/aAAgBAQABPxDcZe5qKLQ79GbUO8SwFYSQgbNyoW9iNjEAJKvRnKa+GtLteWJbW2+/Z//Z')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="span" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "Lisa Naugle",
                  "title": "Lisa Naugle",
                  "src": "/static/4f1c6ac5c7bdd819f2d06ad393d3dd8b/8032a/lisa-naugle_1x1.jpg",
                  "srcSet": ["/static/4f1c6ac5c7bdd819f2d06ad393d3dd8b/8032a/lisa-naugle_1x1.jpg 563w"],
                  "sizes": "(max-width: 563px) 100vw, 563px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy"
                }}></img>{`
    `}</span></span></p>
          <h3 className="compact">Lisa Naugle</h3>
          <h5>Faculty</h5>
          <p>{`Lisa Naugle is an American choreographer, artistic director, improvisor, interactive performance artist and dance educator. Recognized for her solo dance improvisation performances and choreography integrated with live video, Lisa began creating remote, geographically distanced performance in 1997 with the Cassandra Project and later coined the term, “distributed choreography." Her performance work is collaborative, interdisciplinary, often site-specific and with international collaborators. `}</p>
          <ul className="actions special">
  <li><a href="/people/lisa-naugle" className="button small">More</a></li>
          </ul>
        </section>
      </div>
    </section>
    <section className="main style2">
  <footer style={{
        "padding": "36px"
      }}>
    <div className="row aln-center gtr-50 gtr-uniform">
      <NavSecondary mdxType="NavSecondary" />
    </div>
  </footer>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      